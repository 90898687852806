<template>
  <v-container>
    <v-template>
      <div class="header">
        <title-vue :title="title" />
        <status-badge-vue :status="partner.status" />
      </div>
      <v-row class="mt-2">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <p class="subtitle-2">Dados do parceiro</p>
              <v-row class="mb-4">
                <v-col cols="12" md="4">
                  <input-text-vue
                    label="Nome *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.name"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <input-text-vue
                    :label="
                      partner.typeCompany == 'PJ' ? 'Nome fantasia' : 'Como quer ser chamado?'
                    "
                    :required="partner.typeCompany == 'PJ'"
                    :readonly="readonly"
                    v-model="partner.nickName"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <input-text-vue
                    :label="partner.typeCompany == 'PJ' ? 'CNPJ *' : 'CPF *'"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.document"
                    v-mask="partner.typeCompany !== 'PJ' ? '###.###.###-##' : '##.###.###/####-##'"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    :items="person"
                    v-model="partner.typeCompany"
                    :disabled="loading"
                    :readonly="readonly"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <input-text-vue
                    label="Telefone *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.phone"
                    v-mask="'(##) #####-####'"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <input-text-vue
                    label="Email *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.email"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="CNAE"
                    :required="partner.typeCompany == 'PJ'"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.cnaeCode"
                    @keydown="handleBloquedNumber($event)"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    label="Tipo de parceiro"
                    v-model="partner.category.id"
                    :items="categories"
                    :disabled="loading"
                    :readonly="readonly"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    label="Tipo de parceiro"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.category.name"
                  />
                </v-col>
              </v-row>

              <p class="subtitle-2">Dados de endereço</p>
              <v-row class="mb-4">
                <v-col cols="2">
                  <v-text-field
                    label="CEP *"
                    required
                    :disabled="loading"
                    v-model="partner.address.zipCode"
                    v-mask="'#####-###'"
                    :readonly="readonly"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Logradouro *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.street"
                  />
                </v-col>
                <v-col cols="2">
                  <input-text-vue
                    label="Número"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.addressNumber"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Complemento"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.complement"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Bairro *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="partner.address.neighborhood"
                  />
                </v-col>
                <v-col cols="2">
                  <input-text-vue
                    label="UF *"
                    :required="true"
                    :disabled="true"
                    :readonly="true"
                    v-model="partner.address.state"
                  />
                </v-col>
                <v-col cols="4">
                  <input-text-vue
                    label="Cidade *"
                    :required="true"
                    :disabled="true"
                    :readonly="true"
                    v-model="partner.address.cityName"
                  />
                </v-col>
              </v-row>

              <p class="subtitle-2">Horário de Funcionamento</p>
              <v-row class="mb-4">
                <v-col cols="12" md="12">
                  <v-data-table
                    :headers="headersHours"
                    :items="partner.businessHours"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td class="text-center">{{ item.day }}</td>
                          <td class="text-center">{{ item.startTime }}</td>
                          <td class="text-center">{{ item.endTime }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading">
        <v-col class="d-flex justify-start">
          <button-vue dark color="red" class="mr-4" type="cancel" @click="handleCancel"
            >Voltar</button-vue
          >
        </v-col>
      </v-row>
    </v-template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ButtonVue from "@/components/button/Button.vue";
import TitleVue from "@/components/title/Title.vue";
import InputTextVue from "@/components/inputs/InputText.vue";

import { GetPartner } from "@/services/partners/GetPartner";

import { format } from "date-fns";
import { PartnerDetailsType } from "@/types/partner/Partner";
import { CategoryType } from "@/types/category/Category";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

export default defineComponent({
  components: { ButtonVue, TitleVue, InputTextVue, StatusBadgeVue },
  data() {
    return {
      errorsName: "",
      headers: [
        {
          text: "Nome",
          align: "center",
          value: "name",
        },
        {
          text: "CPF",
          align: "center",
          value: "cpf",
          textAlign: "center",
        },
        {
          text: "RG",
          align: "center",
          value: "rg",
        },
      ],
      headersHours: [
        {
          text: "Dia",
          align: "center",
          value: "day",
        },
        {
          text: "Abertura",
          align: "center",
          value: "startTime",
        },
        {
          text: "Fechamento",
          align: "center",
          value: "endTime",
        },
      ],
      loading: false,
      show: false,
      errors: "",
      title: "Novo parceiro",
      person: [
        {
          id: 1,
          text: "Pessoa Fisica",
          value: "PF",
        },
        {
          id: 2,
          text: "Pessoa Jurídica",
          value: "PJ",
        },
      ],
      readonly: false,
      categories: { name: "" } as CategoryType,
      day: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ],
      associateName: "",
      associateCpf: "",
      associateRg: "",
      businessHoursDay: "",
      businessHoursStartTime: "",
      businessHoursEndTime: "",
      cnae: [],
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickName: "",
        tax: 5,
        typeCompany: "PJ",
        category: {
          name: "",
          id: "",
        },
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
      } as PartnerDetailsType,
    };
  },
  methods: {
    validateNumber() {
      this.partner.digitalAccountNumber = this.partner.digitalAccountNumber.replace(/\D/g, "");
    },
    async handleLoadPartner() {
      this.readonly = true;
      this.title = "Detalhes do parceiro";
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute(this.$route.params.id);

      this.partner.id = partner.id || "";
      this.partner.name = partner.name || "";

      this.partner.status = partner.status;

      this.partner.createdAt = partner.createdAt || "";
      this.partner.phone = this.formatPhone(partner.phone) || "";
      this.partner.email = partner.email || "";
      this.partner.document = this.formatDocument(partner.document) || "";

      if (partner.document.length > 11) {
        this.partner.typeCompany = "PJ";
      } else {
        this.partner.typeCompany = "PF";
      }

      if (partner.category) {
        this.partner.category.id = partner.category.id || "";
        this.partner.category.name = partner.category.name || "";
      }

      this.partner.address.street = partner.address.street || "";
      this.partner.address.addressNumber = partner.address.addressNumber || "";
      this.partner.address.neighborhood = partner.address.neighborhood || "";
      this.partner.address.complement = partner.address.complement || "";
      this.partner.address.zipCode = this.formatZipCod(partner.address.zipCode) || "";
      this.partner.address.cityName = partner.address.cityName || "";
      this.partner.address.state = partner.address.state || "";

      this.partner.tax = Number(partner.tax) || 5;
      this.partner.pixkey = partner.pixkey || "";
      this.partner.digitalAccountDocument = partner.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = partner.digitalAccountNumber || "";
      this.partner.cnaeCode = partner.cnaeCode || "";

      this.partner.associate = partner.associate || { name: "", cpf: "", rg: "" };
      this.partner.businessHours = partner.businessHours || { day: "", startTime: "", endTime: "" };

      this.partner.users = partner.users;

      this.loading = false;
    },

    handleCancel() {
      this.$router.push("/partners");
    },

    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
    handleFilterElement(item: any, query: any) {
      return (
        item.descricao.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1 ||
        item.id.indexOf(query) > -1
      );
    },
    handleAddAssociate() {
      if (this.associateCpf == "" || this.associateName == "" || this.associateRg == "") {
        return;
      }
      const indentifyElement = this.partner.associate.some(
        (objeto) => objeto.cpf == this.associateCpf
      );
      if (indentifyElement) {
        this.errors = "Este CPF já foi cadastrado";

        return;
      }

      this.partner.associate.push({
        name: this.associateName,
        cpf: this.associateCpf,
        rg: this.associateRg,
      });
      this.associateName = "";
      this.associateCpf = "";
      this.associateRg = "";
    },
    handleAddBusinessHours() {
      this.partner.businessHours.push({
        day: this.businessHoursDay,
        endTime: this.businessHoursEndTime,
        startTime: this.businessHoursStartTime,
      });
      this.businessHoursDay = "";
      this.businessHoursEndTime = "";
      this.businessHoursStartTime = "";
    },

    handleBloquedNumber(e: any) {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
      ];
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    },
    handleDeleteAssociate(item: any) {
      this.partner.associate = this.partner.associate.filter((element) => element.cpf !== item.cpf);
    },
    handleDeleteBusinessHours(item: any) {
      this.partner.businessHours = this.partner.businessHours.filter(
        (element, index) => index !== item
      );
    },
  },
  watch: {
    cnaeCode(value) {
      this.partner.cnaeCode = value.replace(/[^0-9]/g, "");
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleLoadPartner();
    }
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
@/types/category/Category
